<template>
	<CardItem
		:id="computedId"
		class="c-card-item-employee"
		:class="{
			'c-card-item-employee--has-link': url,
		}"
		v-bind="$props"
	>
		<template #image="{ imageLoadColor }">
			<figure
				v-if="image || usePlaceholder"
				class="c-card-item-employee__image flex items-center px-xs/h"
			>
				<div class="c-card-item-employee__image-form">
					<!-- Image -->
					<div class="c-card-item-employee__photo">
						<BaseImg
							v-if="image"
							class="flex-shrink-0"
							:src="image.cropUrl || image.url"
							:alt="image.altText"
							:sizes="nuxtImgSizes('<1441:140px')"
							:width="image.width"
							:height="image.height"
							:ratio="1"
							:load-color="imageLoadColor"
						/>
						<div
							v-else-if="usePlaceholder"
							class="c-card-item-employee__photo--placeholder"
						></div>
					</div>

					<!-- Rect -->
					<div class="c-card-item-employee__rect"></div>
				</div>
			</figure>
		</template>

		<template #default="{ nameId, teaser }">
			<p
				v-if="computedTitle"
				class="font-light text-body-xs whitespace-pre-wrap pr-xs/h"
			>
				<span
					v-for="(str, index) in computedTitle"
					:key="index"
					class="inline-block"
					v-text="str"
				></span>
			</p>
			<p
				v-if="details && details.company"
				class="text-body-xs whitespace-pre-wrap pr-xs/h italic"
			>
				<span class="inline-block" v-text="details.company"></span>
			</p>

			<div v-if="teaser" class="mt-32 >=768:hidden">
				<AccordionHeader
					:id="headerId"
					tag="BaseButton"
					class="border border-theme px-24 py-10"
					:class="{
						'hover:bg-theme-90': panelIsOpen,
						'text-theme bg-transparent hover:bg-theme hover:text-white':
							!panelIsOpen,
					}"
					:aria-labelledby="`${nameId} ${headerId}`"
					:aria-controls="panelId"
				>
					Yderligere info

					<SvgIconWedge
						class="w-10 >=768:w-12 h-8 ml-1em"
						:class="[
							'transition-transform duration-200 ease-in-out',
							{
								'transform -rotate-180': panelIsOpen,
							},
						]"
					/>
				</AccordionHeader>

				<AccordionPanel
					:id="panelId"
					ref="panel"
					:transition="{
						name: 't-list-item',
					}"
					@change="({ isOpen }) => (panelIsOpen = isOpen)"
				>
					<div class="max-w-text pt-32 >=768:pt-2xs/v">
						<p
							class="
								font-light
								text-body-xs
								whitespace-pre-wrap
								pr-xs/h
							"
							v-html="teaser"
						></p>
					</div>
				</AccordionPanel>
			</div>
		</template>

		<template #footer="{ areas }">
			<div class="mt-auto">
				<!-- Details -->
				<div
					v-if="
						computedPhone ||
						computedEmail ||
						computedDepartments ||
						computedLocation
					"
					class="
						mt-xs/v
						flex
						items-baseline
						gap-x-xs/h gap-y-4
						max-w-max
					"
				>
					<div
						v-if="computedPhone || computedEmail"
						class="
							w-full
							max-w-max
							flex flex-col
							gap-y-4
							flex-shrink flex-grow
						"
					>
						<!-- Phone -->
						<div v-if="computedPhone" class="leading-0">
							<a
								:href="computedPhone.link"
								class="
									text-label
									leading-1.2
									text-red
									hover:underline
									focus:underline
								"
							>
								{{ computedPhone.text }}
							</a>
						</div>

						<!-- Email -->
						<div v-if="computedEmail" class="leading-0">
							<a
								:href="computedEmail.link"
								class="
									text-label
									leading-1.2
									text-red
									hover:underline
									focus:underline
								"
							>
								{{ computedEmail.text }}
							</a>
						</div>
					</div>

					<div
						v-if="computedDepartments || computedLocation"
						class="
							w-1/2
							flex flex-col
							gap-y-6
							flex-shrink flex-grow
						"
					>
						<!-- Departments -->
						<div
							v-if="computedDepartments"
							class="
								text-label-sm
								leading-1.2
								text-gray-darker
								font-medium
								uppercase
							"
						>
							{{ computedDepartments }}
						</div>

						<!-- Location -->
						<div
							v-if="computedLocation"
							class="
								text-label-sm
								leading-1.2
								text-gray-darker
								font-medium
								uppercase
							"
						>
							{{ computedLocation }}
						</div>
					</div>
				</div>

				<!-- Areas -->
				<AreaReferences
					v-if="areas"
					class="c-card-item-employee__areas mt-xs/v"
					:items="areas"
					:show-max="3"
				/>

				<div
					v-if="competencies"
					class="mt-xs/v align-baseline text-red text-label-sm"
					style="max-width: 710px; padding-right: 10px"
				>
					<HeadingX
						v-if="competencies.label"
						class="inline uppercase"
						:level-offset="headingLevelOffset + 1"
						v-text="competencies.label + ': '"
					/>
					<ul class="contents">
						<li
							v-for="(item, index) in competencies.items"
							:key="index"
							class="inline"
							v-text="
								index < competencies.items.length - 1
									? item + ', '
									: item
							"
						></li>
					</ul>
				</div>
			</div>
		</template>
	</CardItem>
</template>

<script>
import HeadingX from '~/components/shared/HeadingX';
import CardItem from '~/components/main/CardItem';
import AreaReferences from '~/components/main/AreaReferences';
import { AccordionHeader, AccordionPanel } from '~/components/shared/Accordion';
import { nuxtImgSizes } from '~/assets/js/utilities/helpers';
import SvgIconWedge from '~/assets/svgs/icon-wedge.svg?inline';

export default {
	name: 'CardItemEmployee',

	components: {
		HeadingX,
		CardItem,
		AreaReferences,
		AccordionHeader,
		AccordionPanel,
		SvgIconWedge,
	},

	props: {
		...CardItem.props,

		// Explicitly used props
		id: {
			type: [String, Number],
			default: '',
		},

		theme: {
			type: String,
			default: 'red',
		},

		image: {
			type: Object,
			default: null,
		},

		usePlaceholder: {
			type: [Boolean, Number],
			default: true,
		},

		details: {
			type: Object,
			default: () => ({}),
		},
	},

	data() {
		return {
			panelIsOpen: false,
		};
	},

	computed: {
		computedId() {
			if (typeof this.id === 'number') {
				return `c-card-item-${this._uid}-${this.id}`;
			}
			return this.id || `c-card-item-${this._uid}`;
		},

		computedTitle() {
			if (Array.isArray(this.details?.title)) {
				return this.details.title.map((title, index) => {
					if (index < this.details.title.length - 1) {
						return `${title}, `;
					}
					return title;
				});
			}
			return this.details?.title || '';
		},

		computedPhone() {
			return (
				this.details?.phone?.text &&
				this.details.phone.link &&
				this.details.phone
			);
		},

		computedEmail() {
			return (
				this.details?.email?.text &&
				this.details.email.link &&
				this.details.email
			);
		},

		computedDepartments() {
			return (
				this.details?.departments?.length &&
				this.details.departments
					.map((department) => department.name)
					.join(', ')
			);
		},

		computedLocation() {
			return this.details?.location?.name;
		},

		competencies() {
			return this.details?.competencies?.items?.length
				? this.details?.competencies
				: null;
		},

		headerId() {
			return `${this.computedId}__header`;
		},

		panelId() {
			return `${this.computedId}__panel`;
		},
	},

	methods: {
		nuxtImgSizes,
	},
};
</script>

<style lang="postcss">
.c-card-item-employee__image {
	overflow: hidden;
	aspect-ratio: 405 / 197;

	& .c-card-item-employee__photo {
		@apply w-full h-full rounded-full overflow-hidden;
		background: rgba(0, 0, 0, 0.04);
		clip-path: circle(50% at 50% 50%);

		& > *,
		& picture {
			@apply w-full h-full rounded-full overflow-hidden;
		}
		& picture {
			transition: scale 0.2s ease-in-out, clip-path 0.2s ease-in-out;
			clip-path: circle(50% at 50% 50%);
		}
	}
}

.c-card-item-employee__image-form {
	@apply relative isolate;
	width: auto;
	height: 71%;
	aspect-ratio: 1;
}

.c-card-item-employee--has-link:hover .c-card-item-employee__image picture,
.c-card-item-employee--has-link:focus-within
	.c-card-item-employee__image
	picture {
	scale: 1.05;
	clip-path: circle(47.5% at 50% 50%);
}

.c-card-item-employee__rect {
	@apply absolute bg-gray-light;
	left: 50%;
	top: 50%;
	width: 100%;
	height: 71%;
	z-index: -1;

	transition: background 0.2s, translate 0.2s ease-in-out;
}

.c-card-item-employee--has-link:hover .c-card-item-employee__rect,
.c-card-item-employee--has-link:focus-within .c-card-item-employee__rect {
	@apply bg-theme-20;
	translate: 5% 0;
}

.c-card-item-employee__areas {
	margin-inline: -4px;
	width: calc(100% + 8px);
}
.c-card-item-employee__areas > :last-child {
	margin-right: 42px;
}
</style>
