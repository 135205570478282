var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SkyAppear',{attrs:{"options":{
		initState: 'outside',
		appearOnce: false,
		triggerEvents: ['resize', 'scroll'],
		thresholds: [0, 0.25, 0.5, 0.75, 1],
		delay: 'animationFrame',
	}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
	var intersectionJourney = ref.intersectionJourney;
	var intersectionRatio = ref.intersectionRatio;
return [_c(_vm.link ? 'NuxtLinkExt' : 'div',{tag:"NuxtLinkExt",staticClass:"\n\t\t\tc-block-list-experts\n\t\t\tc-block-list-experts--block-gray\n\t\t\tc-block-list-experts--wide\n\t\t",class:{
			'c-block-list-experts--has-link': _vm.link,
		},attrs:{"id":("block-" + (_vm.$vnode.key)),"to":_vm.link ? _vm.link.url : null,"target":_vm.link ? _vm.link.target : null}},[_c('TransitionExt',{attrs:{"name":"fade-in-out","duration":"400","appear":""}},[(_vm.computedItems)?_c('figure',{staticClass:"\n\t\t\t\t\tc-block-list-experts__background\n\t\t\t\t\tpointer-events-none\n\t\t\t\t\tabsolute\n\t\t\t\t\tw-visual-screen\n\t\t\t\t\th-full\n\t\t\t\t\tflex\n\t\t\t\t\tjustify-center\n\t\t\t\t\titems-center\n\t\t\t\t\toverflow-hidden\n\t\t\t\t"},[_c('div',{staticClass:"\n\t\t\t\t\t\tabsolute\n\t\t\t\t\t\tw-full\n\t\t\t\t\t\tgrid grid-cols-12\n\t\t\t\t\t\tauto-rows-fr\n\t\t\t\t\t\tgap-16\n\t\t\t\t\t",style:({
						height: '300%',
						transform: ("translateY(" + (Math.round(
								(100 / 3 -
									(100 / 3) * 2 * intersectionJourney) *
									100
							) / 100) + "%)"),
						opacity: ("" + (Math.round(intersectionRatio * 100) / 100)),
					})},[_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-center\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-9 col-span-2\n\t\t\t\t\t\t\trow-start-1 row-span-2\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto h-full",style:({
								aspectRatio: '174 / 202',
								maxHeight: '202px',
							})},[_c('div',{staticClass:"c-block-list-experts__decoration",style:({
									left: '80%',
									top: '20%',
									width: '60%',
									height: '110%',
								})}),_vm._v(" "),_c('div',{staticClass:"c-block-list-experts__image-wrapper"},[(_vm.computedItems[0])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[0].cropUrl ||
										_vm.computedItems[0].url,"alt":_vm.computedItems[0].altText,"width":_vm.computedItems[0].width,"height":_vm.computedItems[0].height,"sizes":"<1441:174px","ratio":174 / 202,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-end\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-3 col-span-2\n\t\t\t\t\t\t\trow-start-2 row-span-1\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto h-full",style:({
								aspectRatio: '1',
								maxHeight: '134px',
							})},[_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__decoration\n\t\t\t\t\t\t\t\t\trounded-full\n\t\t\t\t\t\t\t\t",style:({
									left: '70%',
									top: '70%',
									width: '50%',
									height: '50%',
								})}),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper--round\n\t\t\t\t\t\t\t\t"},[(_vm.computedItems[1])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[1].cropUrl ||
										_vm.computedItems[1].url,"alt":_vm.computedItems[1].altText,"width":_vm.computedItems[1].width,"height":_vm.computedItems[1].height,"sizes":"<1441:134px","ratio":1,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\thidden\n\t\t\t\t\t\t\t>=1024:flex\n\t\t\t\t\t\t\tjustify-end\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-1 col-span-2\n\t\t\t\t\t\t\trow-start-3 row-span-2\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto h-full",style:({
								aspectRatio: '1',
								maxHeight: '175px',
							})},[_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__decoration\n\t\t\t\t\t\t\t\t\trounded-r-full\n\t\t\t\t\t\t\t\t",style:({
									left: '90%',
									width: '50%',
								})}),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper--round\n\t\t\t\t\t\t\t\t"},[(_vm.computedItems[2])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[2].cropUrl ||
										_vm.computedItems[2].url,"alt":_vm.computedItems[2].altText,"width":_vm.computedItems[2].width,"height":_vm.computedItems[2].height,"sizes":"<1441:175px","ratio":1,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-center\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-10 col-span-3\n\t\t\t\t\t\t\trow-start-5\n\t\t\t\t\t\t\t>=1024:row-start-4\n\t\t\t\t\t\t\trow-span-1\n\t\t\t\t\t\t\t>=1024:row-span-3\n\t\t\t\t\t\t"},[_c('div',{staticClass:"c-block-list-experts__image-wrapper"},[(_vm.computedItems[3])?_c('BaseImg',{staticClass:"w-auto h-full object-cover",staticStyle:{"max-height":"273px"},attrs:{"src":_vm.computedItems[3].cropUrl ||
									_vm.computedItems[3].url,"alt":_vm.computedItems[3].altText,"width":_vm.computedItems[3].width,"height":_vm.computedItems[3].height,"sizes":"<1441:375px","ratio":375 / 273,"load-color":"#ffffff","img-attrs":{
									fetchpriority: 'low',
									class: 'object-cover',
								}}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-start\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-6 col-span-1\n\t\t\t\t\t\t\trow-start-6 row-span-1\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto",style:({
								aspectRatio: '153/182',
								height: '150%',
								maxHeight: '182px',
							})},[_c('div',{staticClass:"c-block-list-experts__decoration",style:({
									right: '20%',
									bottom: '50%',
									width: '200%',
								})}),_vm._v(" "),_c('div',{staticClass:"c-block-list-experts__image-wrapper"},[(_vm.computedItems[4])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[4].cropUrl ||
										_vm.computedItems[4].url,"alt":_vm.computedItems[4].altText,"width":_vm.computedItems[4].width,"height":_vm.computedItems[4].height,"sizes":"<1441:153px","ratio":153 / 182,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-center\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-3 col-span-2\n\t\t\t\t\t\t\trow-start-7 row-span-2\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto h-full",style:({
								aspectRatio: '174 / 202',
								maxHeight: '202px',
							})},[_c('div',{staticClass:"c-block-list-experts__decoration",style:({
									left: '80%',
									top: '20%',
									width: '60%',
									height: '110%',
									transform: ("translateY(" + (Math.round(
											intersectionJourney * 100 * 1000
										) /
											1000 -
										50) + "%)"),
								})}),_vm._v(" "),_c('div',{staticClass:"c-block-list-experts__image-wrapper"},[(_vm.computedItems[5])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[5].cropUrl ||
										_vm.computedItems[5].url,"alt":_vm.computedItems[5].altText,"width":_vm.computedItems[5].width,"height":_vm.computedItems[5].height,"sizes":"<1441:174px","ratio":174 / 202,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-start\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-9 col-span-2\n\t\t\t\t\t\t\trow-start-8 row-span-1\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto h-full",style:({
								aspectRatio: '1',
								maxHeight: '134px',
							})},[_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__decoration\n\t\t\t\t\t\t\t\t\trounded-full\n\t\t\t\t\t\t\t\t",style:({
									left: '70%',
									top: '70%',
									width: '50%',
									height: '50%',
									transform: ("translateY(" + (Math.round(
											intersectionJourney * 100 * 1000
										) /
											1000 -
										50) + "%)"),
								})}),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper--round\n\t\t\t\t\t\t\t\t"},[(_vm.computedItems[6])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[6].cropUrl ||
										_vm.computedItems[6].url,"alt":_vm.computedItems[6].altText,"width":_vm.computedItems[6].width,"height":_vm.computedItems[6].height,"sizes":"<1441:134px","ratio":1,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\thidden\n\t\t\t\t\t\t\t>=1024:flex\n\t\t\t\t\t\t\tjustify-start\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-11 col-span-2\n\t\t\t\t\t\t\trow-start-9 row-span-2\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto h-full",style:({
								aspectRatio: '1',
								maxHeight: '175px',
							})},[_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__decoration\n\t\t\t\t\t\t\t\t\trounded-r-full\n\t\t\t\t\t\t\t\t",style:({
									left: '90%',
									width: '50%',
									transform: ("translateY(" + (Math.round(
											intersectionJourney * 100 * 1000
										) /
											1000 -
										50) + "%)"),
								})}),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper--round\n\t\t\t\t\t\t\t\t"},[(_vm.computedItems[7])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[7].cropUrl ||
										_vm.computedItems[7].url,"alt":_vm.computedItems[7].altText,"width":_vm.computedItems[7].width,"height":_vm.computedItems[7].height,"sizes":"<1441:175px","ratio":1,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-center\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-1 col-span-3\n\t\t\t\t\t\t\trow-start-11\n\t\t\t\t\t\t\t>=1024:row-start-10\n\t\t\t\t\t\t\trow-span-1\n\t\t\t\t\t\t\t>=1024:row-span-3\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto h-full",style:({
								aspectRatio: '375/273',
								maxHeight: '273px',
							})},[_c('div',{staticClass:"c-block-list-experts__image-wrapper"},[(_vm.computedItems[8])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[8].cropUrl ||
										_vm.computedItems[8].url,"alt":_vm.computedItems[8].altText,"width":_vm.computedItems[8].width,"height":_vm.computedItems[8].height,"sizes":"<1441:375px","ratio":375 / 273,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-end\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-7 col-span-1\n\t\t\t\t\t\t\trow-start-12 row-span-1\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto",style:({
								aspectRatio: '153/182',
								height: '150%',
								maxHeight: '182px',
							})},[_c('div',{staticClass:"c-block-list-experts__decoration",style:({
									right: '20%',
									bottom: '50%',
									width: '200%',
									transform: ("translateY(" + (Math.round(
											intersectionJourney * 100 * 1000
										) /
											1000 -
										50) + "%)"),
								})}),_vm._v(" "),_c('div',{staticClass:"c-block-list-experts__image-wrapper"},[(_vm.computedItems[9])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[9].cropUrl ||
										_vm.computedItems[9].url,"alt":_vm.computedItems[9].altText,"width":_vm.computedItems[9].width,"height":_vm.computedItems[9].height,"sizes":"<1441:153px","ratio":153 / 182,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-center\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-9 col-span-2\n\t\t\t\t\t\t\trow-start-13 row-span-2\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto h-full",style:({
								aspectRatio: '174 / 202',
								maxHeight: '202px',
							})},[_c('div',{staticClass:"c-block-list-experts__decoration",style:({
									left: '80%',
									top: '20%',
									width: '60%',
									height: '110%',
								})}),_vm._v(" "),_c('div',{staticClass:"c-block-list-experts__image-wrapper"},[(_vm.computedItems[10])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[10].cropUrl ||
										_vm.computedItems[10].url,"alt":_vm.computedItems[10].altText,"width":_vm.computedItems[10].width,"height":_vm.computedItems[10].height,"sizes":"<1441:174px","ratio":174 / 202,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-end\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-3 col-span-2\n\t\t\t\t\t\t\trow-start-14 row-span-1\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto h-full",style:({
								aspectRatio: '1',
								maxHeight: '134px',
							})},[_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__decoration\n\t\t\t\t\t\t\t\t\trounded-full\n\t\t\t\t\t\t\t\t",style:({
									left: '70%',
									top: '70%',
									width: '50%',
									height: '50%',
								})}),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper--round\n\t\t\t\t\t\t\t\t"},[(_vm.computedItems[1])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[1].cropUrl ||
										_vm.computedItems[1].url,"alt":_vm.computedItems[1].altText,"width":_vm.computedItems[1].width,"height":_vm.computedItems[1].height,"sizes":"<1441:134px","ratio":1,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\thidden\n\t\t\t\t\t\t\t>=1024:flex\n\t\t\t\t\t\t\tjustify-end\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-1 col-span-2\n\t\t\t\t\t\t\trow-start-15 row-span-2\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto h-full",style:({
								aspectRatio: '1',
								maxHeight: '175px',
							})},[_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__decoration\n\t\t\t\t\t\t\t\t\trounded-r-full\n\t\t\t\t\t\t\t\t",style:({
									left: '90%',
									width: '50%',
								})}),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper\n\t\t\t\t\t\t\t\t\tc-block-list-experts__image-wrapper--round\n\t\t\t\t\t\t\t\t"},[(_vm.computedItems[12])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[12].cropUrl ||
										_vm.computedItems[12].url,"alt":_vm.computedItems[12].altText,"width":_vm.computedItems[12].width,"height":_vm.computedItems[12].height,"sizes":"<1441:175px","ratio":1,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-center\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-10 col-span-3\n\t\t\t\t\t\t\trow-start-17\n\t\t\t\t\t\t\t>=1024:row-start-16\n\t\t\t\t\t\t\trow-span-1\n\t\t\t\t\t\t\t>=1024:row-span-3\n\t\t\t\t\t\t"},[_c('div',{staticClass:"c-block-list-experts__image-wrapper"},[(_vm.computedItems[13])?_c('BaseImg',{staticClass:"w-auto h-full object-cover",staticStyle:{"max-height":"273px"},attrs:{"src":_vm.computedItems[13].cropUrl ||
									_vm.computedItems[13].url,"alt":_vm.computedItems[13].altText,"width":_vm.computedItems[13].width,"height":_vm.computedItems[13].height,"sizes":"<1441:375px","ratio":375 / 273,"load-color":"#ffffff","img-attrs":{
									fetchpriority: 'low',
									class: 'object-cover',
								}}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\tjustify-start\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tcol-start-6 col-span-1\n\t\t\t\t\t\t\trow-start-18 row-span-1\n\t\t\t\t\t\t"},[_c('div',{staticClass:"relative w-auto",style:({
								aspectRatio: '153/182',
								height: '150%',
								maxHeight: '182px',
							})},[_c('div',{staticClass:"c-block-list-experts__decoration",style:({
									right: '20%',
									bottom: '50%',
									width: '200%',
								})}),_vm._v(" "),_c('div',{staticClass:"c-block-list-experts__image-wrapper"},[(_vm.computedItems[14])?_c('BaseImg',{staticClass:"w-full h-full",attrs:{"src":_vm.computedItems[14].cropUrl ||
										_vm.computedItems[14].url,"alt":_vm.computedItems[14].altText,"width":_vm.computedItems[14].width,"height":_vm.computedItems[14].height,"sizes":"<1441:153px","ratio":153 / 182,"load-color":"#ffffff","img-attrs":{
										fetchpriority: 'low',
										class: 'object-cover',
									},"quality":"70"}}):_vm._e()],1)])])])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\tc-block-list-experts__content\n\t\t\t\trelative\n\t\t\t\tw-visual-screen\n\t\t\t\th-full\n\t\t\t\tflex-shrink-0 flex\n\t\t\t\tjustify-center\n\t\t\t\titems-center\n\t\t\t\ttext-center\n\t\t\t"},[(_vm.title)?_c('LongReadTarget',{staticClass:"relative",attrs:{"container-id":("block-" + (_vm.$vnode.key)),"link-to-toc":{
					name: ("Spring til \"" + _vm.title + "\" i indholdsfortegnelsen"),
					attrs: {
						'data-focus-ignore': true,
					},
				},"title":_vm.title,"disabled":!_vm.showTitleInToc}},[_c('BaseH2',{staticClass:"\n\t\t\t\t\t\ttext-cta-title\n\t\t\t\t\t\tfont-extralight\n\t\t\t\t\t\tmax-w-4/4col\n\t\t\t\t\t\t>=768:max-w-8/12col\n\t\t\t\t\t\t>=1024:max-w-6/12col\n\t\t\t\t\t",domProps:{"innerHTML":_vm._s(_vm.title)}})],1):_vm._e()],1),_vm._v(" "),_c('SvgIconGt',{staticClass:"c-block-list-experts__link-indicator"})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }