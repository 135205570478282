<template>
	<ScopedColorTheme tag="header" theme="red" class="c-frontpage-header">
		<div
			class="
				c-frontpage-header__main
				relative
				px-layout-margin
				pt-site-header
				text-white
			"
		>
			<HeaderGraphic
				v-if="headerGraphic > -2"
				class="c-frontpage-header__header-graphic"
				style="z-index: -1"
				:index="headerGraphic"
			/>

			<Portal v-if="intro && intro.tagline" to="SiteHeaderText">
				<span v-html="intro.tagline"></span>
			</Portal>

			<div
				v-if="intro && intro.tagline"
				class="c-frontpage-header__tagline"
			>
				<span v-html="intro.tagline"></span>
			</div>

			<LinkTile
				v-if="spot"
				:component="typeof spot.url === 'string' ? 'LinkTile' : 'div'"
				:to="spot.url"
				class="
					mt-52
					group
					self-stretch
					grid grid-cols-1
					>=768:grid-cols-2
					items-center
					pointer-events-none
				"
				:aria-labelledby="
					spot.title || spot.name
						? 'c-frontpage-header__spot-title'
						: 'c-frontpage-header__spot-link'
				"
				:custom-link-attrs="{
					'data-focus': '#c-frontpage-header__spot-link',
				}"
			>
				<!-- Image -->
				<BaseImg
					v-if="spot.image"
					:src="spot.image.cropUrl || spot.image.url"
					:alt="spot.image.altText"
					class="
						c-frontpage-header__spot-image
						self-stretch
						pointer-events-auto
						w-full
						overflow-hidden
						right-layout-margin
						>=768:right-0
					"
					:sizes="nuxtImgSizes('<1441:728px')"
					:width="spot.image.width"
					:height="spot.image.height"
					:ratio="728 / 514"
					:img-attrs="{
						fetchpriority: 'high',
						class: 'object-cover transform group-data-hover:translate-x-8 group-focus-within:translate-x-8 transition-transform duration-500',
					}"
				/>

				<!-- Content -->
				<div
					class="
						pt-2xs/v
						>=768:pt-2xl/v
						pb-md/v
						>=768:pb-lg/v >=768:pl-(1/2)/12col
						pr-(1/2)/12col
						>=768:pr-0
						<768:order-1
					"
				>
					<div
						id="c-frontpage-header__spot-link"
						class="
							pointer-events-auto
							>=768:pr-(1/2)/12col
							transform
							group-data-hover:translate-x-8
							group-focus-within:translate-x-8
							transition-transform
							duration-500
						"
					>
						<small
							v-if="spot.label"
							class="
								w-full
								flex
								items-center
								mb-lg/v
								test-label-sm
							"
						>
							<SvgIconGt v-if="false" class="w-16 h-16" />
							<span
								class="uppercase font-medium"
								v-text="spot.label"
							></span>
						</small>

						<BaseH1
							v-if="spot.title || spot.name"
							id="c-frontpage-header__spot-title"
							class="text-h1-frontpage font-bold"
							v-html="spot.title || spot.name"
						/>
					</div>
				</div>
			</LinkTile>
		</div>
	</ScopedColorTheme>
</template>

<script>
import ScopedColorTheme from '~/components/main/ScopedColorTheme';
import HeaderGraphic from '~/components/main/HeaderGraphic';
import LinkTile from '~/components/shared/LinkTile';
import { nuxtImgSizes } from '~/assets/js/utilities/helpers';
import SvgIconGt from '~/assets/svgs/icon-gt.svg?inline';

export default {
	name: 'FrontpageHeader',

	components: {
		ScopedColorTheme,
		HeaderGraphic,
		LinkTile,
		SvgIconGt,
	},

	props: {
		intro: Object,
		spot: Object,
		headerGraphic: {
			type: Number,
			default: 2,
		},
	},

	methods: {
		nuxtImgSizes,
	},
};
</script>

<style lang="postcss">
.c-frontpage-header__tagline {
	@apply pr-(1/2)/12col text-teaser;
	margin-top: 1em;
	margin-bottom: -1em;
}
@screen >=768 {
	.c-frontpage-header__tagline {
		@apply w-6/12col m-0 ml-auto pr-0 pl-(1/2)/12col;
	}
}
@screen >=1200 {
	.c-frontpage-header__tagline {
		display: none;
	}
}
.c-frontpage-header__main:before {
	@apply absolute bg-theme;
	content: '';
	left: calc(var(--margin, 0px) * -1);
	top: 0;
	width: calc(100% + var(--margin, 0px) * 2);
	height: 100%;
	z-index: -1;
}
.c-frontpage-header__header-graphic {
	@apply absolute;
	top: 0;
	left: auto;
	right: calc(var(--margin, 0px) * -1);
	width: calc(100% + var(--margin, 0px) * 2);
	height: 100%;
	z-index: -1;
}
@screen >=1441 {
	.c-frontpage-header__main {
		--margin: (
				var(--visual-viewport-width, 100vw) -
					var(--theme-layout-max, 1440px)
			) / 2;
	}
}
.c-frontpage-header__spot-image {
	aspect-ratio: 728 / 514 !important;
	height: auto;
}
.c-frontpage-header__spot-image img {
	@apply relative;
	max-width: unset;
	width: calc(100% + 8px);
	left: -8px;
}

@screen print {
	.c-frontpage-header,
	.c-frontpage-header__main > * {
		@apply text-text;
	}
	.c-frontpage-header__main {
		@apply contents;

		&:before {
			@apply hidden;
		}
	}
	.c-frontpage-header__header-graphic {
		@apply hidden;
	}
}
</style>
