var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"d-frontpage",class:{
		'd-frontpage--no-footer-gap': !_vm.hasFooterGap,
	}},[_c('FrontpageHeader',{attrs:{"intro":_vm.intro,"spot":_vm.spot}}),_vm._v(" "),_c('ScopedColorTheme',{staticClass:"w-full px-layout-margin",attrs:{"theme":"blue"}},[(_vm.areas && _vm.areas.items && _vm.areas.items.length)?_c('FrontpageAreas',_vm._b({staticClass:"mt-xl/v"},'FrontpageAreas',_vm.areas,false)):_vm._e(),_vm._v(" "),(_vm.focus && _vm.focus.items && _vm.focus.items.length)?_c('FrontpageFocus',_vm._b({staticClass:"mt-xl/v",attrs:{"no-bottom-line":_vm.expertsLast}},'FrontpageFocus',_vm.focus,false)):_vm._e(),_vm._v(" "),(_vm.themes && _vm.themes.items && _vm.themes.items.length)?_c('FrontpageThemes',_vm._b({staticClass:"mt-xl/v"},'FrontpageThemes',_vm.themes,false)):_vm._e(),_vm._v(" "),(
				_vm.experts &&
				_vm.experts.content &&
				_vm.experts.content.items &&
				_vm.experts.content.items.length
			)?_c('FrontpageExperts',{staticClass:"mt-xl/v",attrs:{"block":_vm.experts}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }