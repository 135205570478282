<template>
	<div class="c-frontpage-focus">
		<BaseH2
			v-if="title"
			class="text-h3 text-gray-darker mb-sm/v"
			v-html="title"
		/>

		<div
			v-if="items && items.length"
			class="
				grid grid-cols-1
				>=768:grid-cols-2
				gap-x-1/12col gap-y-xl/v
				w-full
			"
			:class="{
				'>=768:border-b >=768:border-gray-medium': !noBottomLine,
			}"
		>
			<ScopedColorTheme
				v-for="(item, index) in items"
				:key="item.id"
				class="w-full pb-sm/v >=768:pb-lg/v border-gray-medium"
				:class="{
					'border-b': index < items.length - 1 || !noBottomLine,
					'>=768:border-b-0': index > items.length - 3,
					'>=768:row-span-2': index >= 1 && index <= 2,
				}"
				:theme="item.colorScheme || 'blue'"
			>
				<LinkTile
					class="group w-full"
					:class="{
						'>=768:flex >=768:gap-x-sm/h':
							index === 0 || index === 3,
						'>=768:h-full':
							(index === 0 || index === 3) && item.image,
					}"
					:to="item.url"
					:aria-labelledby="`c-frontpage-focus__item-name-${item.id}`"
				>
					<BaseImg
						v-if="item.image"
						class="
							c-frontpage-focus__image
							w-full
							flex
							justify-end
							overflow-hidden
							mb-xs/v
						"
						:class="{
							'>=768:w-2/12col >=768:h-full >=768:mb-0':
								index === 0 || index === 3,
						}"
						:src="item.image.cropUrl || item.image.url"
						:alt="item.image.altText"
						:sizes="
							nuxtImgSizes('<768:92vw <1400:43vw <1441:580px')
						"
						:img-attrs="{
							class: 'object-cover transform group-data-hover:translate-x-8 group-focus-within:translate-x-8 transition-transform duration-500',
						}"
						:width="item.image.width"
						:height="item.image.height"
						:ratio="351 / 193"
					/>
					<div
						class="w-full h-full flex-1"
						:class="{
							'>=768:w-1/2': index === 0 || index === 3,
						}"
					>
						<small
							v-if="item.label"
							class="
								font-medium
								uppercase
								text-label-sm text-theme
								mb-8
							"
							v-text="item.label"
						></small>

						<BaseH3
							v-if="item.title || item.name"
							:id="`c-frontpage-focus__item-name-${item.id}`"
							:level-offset="title ? 2 : 1"
							class="
								text-sub-teaser
								>=768:text-card-title >=768:font-semibold
							"
							v-html="item.title || item.name"
						/>

						<p
							v-if="item.teaser"
							class="
								hidden
								>=768:block
								mt-16
								font-light
								text-body
							"
							v-html="item.teaser"
						></p>
					</div>
				</LinkTile>
			</ScopedColorTheme>
		</div>
	</div>
</template>

<script>
import ScopedColorTheme from '~/components/main/ScopedColorTheme';
import LinkTile from '~/components/shared/LinkTile';
import { nuxtImgSizes } from '~/assets/js/utilities/helpers';

export default {
	name: 'FrontpageAreas',

	components: {
		ScopedColorTheme,
		LinkTile,
	},

	props: {
		title: String,
		items: {
			type: Array,
			default: () => [],
		},
		noBottomLine: Boolean,
	},

	methods: {
		nuxtImgSizes,
	},
};
</script>

<style lang="postcss">
.c-frontpage-focus__image {
	aspect-ratio: 343 / 193 !important;

	& img {
		@apply flex-shrink-0;
		width: calc(100% + 8px);
		max-width: calc(100% + 8px);
	}
}
</style>
