<template>
	<BlockListExperts v-if="block" :key="block.key" v-bind="block.content" />
</template>

<script>
import BlockListExperts from '~/components/main/BlockList/blocks/BlockListExperts';

export default {
	name: 'FrontpageExperts',

	components: {
		BlockListExperts,
	},
	inheritAttrs: false,

	props: {
		block: {
			type: Object,
			default: null,
		},
	},
};
</script>
