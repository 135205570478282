<template>
	<div class="c-frontpage-areas">
		<BaseH2
			v-if="title"
			class="text-h3 text-gray-darker"
			:class="{
				'mb-5xs/v': teaser,
				'mb-sm/v': !teaser,
			}"
			v-html="title"
		/>

		<p
			v-if="teaser"
			class="
				>=1024:w-8/12col
				>=1200:w-9/12col
				text-gray-darker text-body
				font-light
				mb-sm/v
			"
			v-text="teaser"
		></p>

		<div
			class="
				grid grid-cols-2
				>=1024:grid-cols-3
				>=1200:grid-cols-4
				gap-1
				w-full
				bg-blue bg-opacity-70
			"
		>
			<LinkTile
				v-for="item in items"
				:key="item.key"
				:to="item.url"
				class="
					c-frontpage-areas__item
					group
					bg-blue
					text-white
					overflow-hidden
				"
				:aria-labelledby="
					[
						item.title || item.name
							? `c-frontpage-areas__title-${item.key}`
							: null,
						`c-frontpage-areas__button-${item.key}`,
					]
						.filter(Boolean)
						.join(' ')
				"
			>
				<article
					class="
						c-frontpage-areas__content
						flex flex-col
						justify-between
						w-full
						h-full
						px-xs/h
						py-xs/v
					"
				>
					<header
						class="
							w-full
							h-full
							>=768:h-auto
							mb-xs/v
							flex flex-col
							>=768:flex-row
							gap-x-2xs/h gap-y-28
							>=768:items-center
						"
						:style="{
							minHeight: 'var(--icon-size, 48px)',
						}"
					>
						<AreaIcon
							:style="{
								width: 'var(--icon-size, 48px)',
								height: 'var(--icon-size, 48px)',
							}"
							class="self-start flex-shrink-0"
							:src="item.iconUrl"
							invert
						/>

						<BaseH4
							v-if="item.title || item.name"
							:id="`c-frontpage-areas__title-${item.key}`"
							class="
								c-frontpage-areas__heading
								inline-flex
								items-center
								mt-auto
								>=768:mt-0
								<768:min-h-0
							"
							:style="{
								minHeight: 'var(--icon-size, 48px)',
							}"
							:level-offset="title ? 2 : 1"
							v-html="item.title || item.name"
						/>
					</header>
					<ul
						class="
							c-frontpage-areas__hidden-content
							hidden
							>=768:flex
							flex-wrap
							gap-8
						"
					>
						<li v-for="(link, index) in item.links" :key="index">
							<NuxtLinkExt
								:to="link.url"
								:target="link.target"
								class="
									inline-flex
									items-center
									px-10
									py-5
									min-h-28
									border border-white border-opacity-30
									text-white
									hover:bg-white
									hover:border-opacity-100
									hover:text-theme
									focus:bg-white
									focus:border-opacity-100
									focus:text-theme
									transition-colors
									duration-200
									ease-in-out
									rounded-25
									text-body-xs
									-mx-1
								"
							>
								{{ link.name }}
							</NuxtLinkExt>
						</li>
						<li key="read-more">
							<div
								:id="`c-frontpage-areas__button-${item.key}`"
								class="
									inline-flex
									items-center
									px-10
									py-5
									min-h-28
									border border-white border-opacity-30
									text-white
									group-hover:bg-white
									group-hover:border-opacity-100
									group-hover:text-theme
									group-focus-within:bg-white
									group-focus-within:border-opacity-100
									group-focus-within:text-theme
									transition-colors
									duration-200
									ease-in-out
									rounded-25
									text-body-xs
									-mx-1
								"
							>
								Læs mere
							</div>
						</li>
					</ul>
				</article>
			</LinkTile>

			<!-- Filler -->
			<div
				v-for="(item, index) in 3"
				:key="index"
				class="c-frontpage-areas__stand-in bg-blue"
			></div>
		</div>
	</div>
</template>

<script>
import LinkTile from '~/components/shared/LinkTile';
import AreaIcon from '~/components/main/AreaIcon';

export default {
	name: 'FrontpageAreas',

	components: {
		LinkTile,
		AreaIcon,
	},

	props: {
		title: String,
		teaser: String,
		items: {
			type: Array,
			default: () => [],
		},
	},
};
</script>

<style lang="postcss">
.c-frontpage-areas__content {
	--icon-size: 40px;
}
@screen >=768 {
	.c-frontpage-areas__content {
		--icon-size: 48px;
		transform: translateY(100%)
			translateY(
				calc(
					var(
							--theme-verticalSpacing-xs-v,
							var(--theme-verticalSpacing-xs-v--sm)
						) * -2 - var(--icon-size, 48px)
				)
			);
		transition: transform 0.3s ease;
	}
	.c-frontpage-areas__hidden-content {
		opacity: 0;
		transition: opacity 0.3s ease;
	}
	.c-frontpage-areas__heading {
		transform: translateY(-100%) translateY(var(--icon-size, 48px));
		transition: transform 0.3s ease;
	}

	.c-frontpage-areas__item:hover,
	.c-frontpage-areas__item:focus-within {
		& .c-frontpage-areas__content,
		& .c-frontpage-areas__heading {
			transform: translateY(0);
		}
		& .c-frontpage-areas__hidden-content {
			opacity: 1;
		}
	}
}

.c-frontpage-areas__stand-in {
	display: none;
}

@screen >=1200 {
	:not(.c-frontpage-areas__stand-in):nth-child(4n + 1)
		+ .c-frontpage-areas__stand-in:nth-child(4n + 2),
	:not(.c-frontpage-areas__stand-in):nth-child(4n + 1)
		+ *
		+ .c-frontpage-areas__stand-in:nth-child(4n + 3),
	:not(.c-frontpage-areas__stand-in):nth-child(4n + 1)
		+ *
		+ *
		+ .c-frontpage-areas__stand-in:nth-child(4n + 4) {
		display: block;
	}
}

@screen <1200 {
	@screen >=1024 {
		:not(.c-frontpage-areas__stand-in):nth-child(3n + 1)
			+ .c-frontpage-areas__stand-in:nth-child(3n + 2),
		:not(.c-frontpage-areas__stand-in):nth-child(3n + 1)
			+ *
			+ .c-frontpage-areas__stand-in:nth-child(3n + 3) {
			display: block;
		}
	}
}

@screen <1024 {
	:not(.c-frontpage-areas__stand-in):nth-child(2n + 1)
		+ .c-frontpage-areas__stand-in:nth-child(2n + 2) {
		display: block;
	}
}
</style>
