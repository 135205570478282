var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.theme ? 'ScopedColorTheme' : 'div',{tag:"ScopedColorTheme",staticClass:"c-card-item",class:{
		'c-card-item--has-link': _vm.url,
	},attrs:{"theme":_vm.theme}},[_c(_vm.url ? 'LinkTile' : _vm.tag,{tag:"Component",attrs:{"id":_vm.computedId,"tag":_vm.tag,"aria-labelledby":_vm.ariaLabelledBy,"to":_vm.url}},[_vm._t("image",function(){return [(_vm.iconUrl && (!_vm.template || _vm.template === 'area'))?_c('figure',{staticClass:"\n\t\t\t\t\tc-card-item__image c-card-item__image--icon\n\t\t\t\t\tpx-xs/h\n\t\t\t\t\tflex\n\t\t\t\t\titems-center\n\t\t\t\t"},[_c('AreaIcon',{staticClass:"w-auto h-1/2",attrs:{"src":_vm.iconUrl}})],1):((_vm.image || _vm.usePlaceholder) && _vm.template !== 'area')?_c('figure',{staticClass:"c-card-item__image"},[(_vm.image)?_c('BaseImg',{staticClass:"flex-shrink-0",attrs:{"src":_vm.image.cropUrl || _vm.image.url,"alt":_vm.image.altText,"sizes":_vm.nuxtImgSizes('<1200:100vw <1400:600px <1441:800px'),"width":_vm.image.width,"height":_vm.image.height,"ratio":413 / 197,"load-color":_vm.imageLoadColor}}):_c('BasePlaceholder',{staticClass:"h-full",attrs:{"index":_vm.placeholderIndex}})],1):_vm._e()]},null,_vm.slotProps),_vm._v(" "),_c('div',{staticClass:"flex flex-col flex-grow px-xs/h py-xs/v"},[_vm._t("header",function(){return [(_vm.label || _vm.computedDate)?_c('small',[(_vm.label)?_c('span',{staticClass:"\n\t\t\t\t\t\t\tinline-block\n\t\t\t\t\t\t\tfont-medium\n\t\t\t\t\t\t\tuppercase\n\t\t\t\t\t\t\ttext-label-sm text-theme\n\t\t\t\t\t\t",attrs:{"id":_vm.labelId},domProps:{"textContent":_vm._s(_vm.label)}}):_vm._e(),_vm._v(" "),(_vm.label && _vm.computedDate)?_c('br'):_vm._e(),_vm._v(" "),(_vm.computedDate)?_c('span',{staticClass:"\n\t\t\t\t\t\t\tc-card-item__date-stamp\n\t\t\t\t\t\t\tinline-block\n\t\t\t\t\t\t\tfont-medium\n\t\t\t\t\t\t\tuppercase\n\t\t\t\t\t\t\ttext-label-sm text-gray-darker\n\t\t\t\t\t\t",domProps:{"textContent":_vm._s(_vm.computedDate)}}):_vm._e()]):_vm._e()]},null,_vm.slotProps),_vm._v(" "),_vm._t("content",function(){return [(
						_vm.computedName ||
						(_vm.computedTeaser && _vm.template !== 'employee')
					)?_c('div',{staticClass:"flex flex-col mt-4xs/v mb-4xs/v gap-y-2xs/v"},[(_vm.computedName)?_c('BaseH4',{staticClass:"text-card-title-sm-2",attrs:{"id":_vm.nameId,"level-offset":_vm.headingLevelOffset},domProps:{"innerHTML":_vm._s(_vm.computedName)}}):_vm._e(),_vm._v(" "),(_vm.computedApaDetails)?_c('p',{staticClass:"\n\t\t\t\t\t\t\tfont-light\n\t\t\t\t\t\t\ttext-body-xs\n\t\t\t\t\t\t\twhitespace-pre-wrap\n\t\t\t\t\t\t\tpr-xs/h\n\t\t\t\t\t\t",domProps:{"innerHTML":_vm._s(_vm.computedApaDetails)}}):(
							_vm.computedTeaser && _vm.template !== 'employee'
						)?_c('p',{staticClass:"\n\t\t\t\t\t\t\tfont-light\n\t\t\t\t\t\t\ttext-body-xs\n\t\t\t\t\t\t\twhitespace-pre-wrap\n\t\t\t\t\t\t\tpr-xs/h\n\t\t\t\t\t\t",domProps:{"innerHTML":_vm._s(_vm.computedTeaser)}}):_vm._e(),_vm._v(" "),(_vm.$scopedSlots.content)?_vm._t("default",null,null,_vm.slotProps):_vm._e()],2):_vm._e()]},null,_vm.slotProps),_vm._v(" "),(!_vm.$scopedSlots.content)?_vm._t("default",null,null,_vm.slotProps):_vm._e(),_vm._v(" "),_vm._t("footer",function(){return [_c('div',{staticClass:"mt-auto"},[(_vm.areas)?_c('AreaReferences',{staticClass:"c-card-item__areas mt-xs/v",attrs:{"items":_vm.areas}}):_vm._e()],1)]},null,_vm.slotProps),_vm._v(" "),_vm._t("indicator",function(){return [_c('SvgIconGt',{staticClass:"c-card-item__link-indicator"})]},null,_vm.slotProps)],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }