var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ScopedColorTheme',{staticClass:"c-frontpage-header",attrs:{"tag":"header","theme":"red"}},[_c('div',{staticClass:"\n\t\t\tc-frontpage-header__main\n\t\t\trelative\n\t\t\tpx-layout-margin\n\t\t\tpt-site-header\n\t\t\ttext-white\n\t\t"},[(_vm.headerGraphic > -2)?_c('HeaderGraphic',{staticClass:"c-frontpage-header__header-graphic",staticStyle:{"z-index":"-1"},attrs:{"index":_vm.headerGraphic}}):_vm._e(),_vm._v(" "),(_vm.intro && _vm.intro.tagline)?_c('Portal',{attrs:{"to":"SiteHeaderText"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.intro.tagline)}})]):_vm._e(),_vm._v(" "),(_vm.intro && _vm.intro.tagline)?_c('div',{staticClass:"c-frontpage-header__tagline"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.intro.tagline)}})]):_vm._e(),_vm._v(" "),(_vm.spot)?_c('LinkTile',{staticClass:"\n\t\t\t\tmt-52\n\t\t\t\tgroup\n\t\t\t\tself-stretch\n\t\t\t\tgrid grid-cols-1\n\t\t\t\t>=768:grid-cols-2\n\t\t\t\titems-center\n\t\t\t\tpointer-events-none\n\t\t\t",attrs:{"component":typeof _vm.spot.url === 'string' ? 'LinkTile' : 'div',"to":_vm.spot.url,"aria-labelledby":_vm.spot.title || _vm.spot.name
					? 'c-frontpage-header__spot-title'
					: 'c-frontpage-header__spot-link',"custom-link-attrs":{
				'data-focus': '#c-frontpage-header__spot-link',
			}}},[(_vm.spot.image)?_c('BaseImg',{staticClass:"\n\t\t\t\t\tc-frontpage-header__spot-image\n\t\t\t\t\tself-stretch\n\t\t\t\t\tpointer-events-auto\n\t\t\t\t\tw-full\n\t\t\t\t\toverflow-hidden\n\t\t\t\t\tright-layout-margin\n\t\t\t\t\t>=768:right-0\n\t\t\t\t",attrs:{"src":_vm.spot.image.cropUrl || _vm.spot.image.url,"alt":_vm.spot.image.altText,"sizes":_vm.nuxtImgSizes('<1441:728px'),"width":_vm.spot.image.width,"height":_vm.spot.image.height,"ratio":728 / 514,"img-attrs":{
					fetchpriority: 'high',
					class: 'object-cover transform group-data-hover:translate-x-8 group-focus-within:translate-x-8 transition-transform duration-500',
				}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\tpt-2xs/v\n\t\t\t\t\t>=768:pt-2xl/v\n\t\t\t\t\tpb-md/v\n\t\t\t\t\t>=768:pb-lg/v >=768:pl-(1/2)/12col\n\t\t\t\t\tpr-(1/2)/12col\n\t\t\t\t\t>=768:pr-0\n\t\t\t\t\t<768:order-1\n\t\t\t\t"},[_c('div',{staticClass:"\n\t\t\t\t\t\tpointer-events-auto\n\t\t\t\t\t\t>=768:pr-(1/2)/12col\n\t\t\t\t\t\ttransform\n\t\t\t\t\t\tgroup-data-hover:translate-x-8\n\t\t\t\t\t\tgroup-focus-within:translate-x-8\n\t\t\t\t\t\ttransition-transform\n\t\t\t\t\t\tduration-500\n\t\t\t\t\t",attrs:{"id":"c-frontpage-header__spot-link"}},[(_vm.spot.label)?_c('small',{staticClass:"\n\t\t\t\t\t\t\tw-full\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tmb-lg/v\n\t\t\t\t\t\t\ttest-label-sm\n\t\t\t\t\t\t"},[(false)?_c('SvgIconGt',{staticClass:"w-16 h-16"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"uppercase font-medium",domProps:{"textContent":_vm._s(_vm.spot.label)}})],1):_vm._e(),_vm._v(" "),(_vm.spot.title || _vm.spot.name)?_c('BaseH1',{staticClass:"text-h1-frontpage font-bold",attrs:{"id":"c-frontpage-header__spot-title"},domProps:{"innerHTML":_vm._s(_vm.spot.title || _vm.spot.name)}}):_vm._e()],1)])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }