<template>
	<CardListSection
		:id="`c-frontpage-themes-${$vnode.key}`"
		v-bind="$props"
		:is-gray="false"
		show-link-at-bottom
		class="c-frontpage-themes"
	/>
</template>

<script>
import CardListSection from '~/components/main/CardListSection';

export default {
	name: 'FrontpageThemes',

	components: {
		CardListSection,
	},
	inheritAttrs: false,

	props: {
		title: {
			type: String,
			default: null,
		},
		teaser: {
			type: String,
			default: null,
		},

		items: {
			type: Array,
			default: () => [],
		},

		link: {
			type: Object,
			default: null,
		},
	},
};
</script>

<style lang="postcss">
.c-frontpage-themes h2 {
	@apply text-gray-darker;
}
.c-frontpage-themes .c-card-item {
	& figure + div * {
		@apply text-white !important;
	}
	& > * {
		@apply bg-theme;

		&:before {
			@apply bg-theme-40 bg-opacity-20;
		}
	}
}
</style>
