<template>
	<article
		class="d-frontpage"
		:class="{
			'd-frontpage--no-footer-gap': !hasFooterGap,
		}"
	>
		<FrontpageHeader :intro="intro" :spot="spot" />

		<ScopedColorTheme class="w-full px-layout-margin" theme="blue">
			<FrontpageAreas
				v-if="areas && areas.items && areas.items.length"
				class="mt-xl/v"
				v-bind="areas"
			/>

			<FrontpageFocus
				v-if="focus && focus.items && focus.items.length"
				class="mt-xl/v"
				:no-bottom-line="expertsLast"
				v-bind="focus"
			/>

			<FrontpageThemes
				v-if="themes && themes.items && themes.items.length"
				class="mt-xl/v"
				v-bind="themes"
			/>

			<FrontpageExperts
				v-if="
					experts &&
					experts.content &&
					experts.content.items &&
					experts.content.items.length
				"
				class="mt-xl/v"
				:block="experts"
			/>
		</ScopedColorTheme>
	</article>
</template>

<script>
import ScopedColorTheme from '~/components/main/ScopedColorTheme';
import FrontpageHeader from '~/components/main/FrontpageHeader';
import FrontpageAreas from '~/components/main/FrontpageAreas';
import FrontpageFocus from '~/components/main/FrontpageFocus';
import FrontpageThemes from '~/components/main/FrontpageThemes';
import FrontpageExperts from '~/components/main/FrontpageExperts';

export default {
	name: 'MainFrontpage',

	components: {
		ScopedColorTheme,
		FrontpageHeader,
		FrontpageAreas,
		FrontpageFocus,
		FrontpageThemes,
		FrontpageExperts,
	},

	inheritAttrs: false,

	props: {
		intro: Object,
		spot: Object,
		areas: {
			type: Object,
			default: null,
		},
		focus: {
			type: Object,
			default: null,
		},
		themes: {
			type: Object,
			default: null,
		},
		experts: {
			type: Object,
			default: null,
		},
	},

	computed: {
		expertsLast() {
			if (
				this.focus &&
				!this.themes &&
				!this.experts?.content?.items?.length
			) {
				return true;
			}
			return false;
		},
		lastBlockIsGrayBlock() {
			if (this.focus && !this.themes) {
				return true;
			}
			return !!this.experts?.content?.items?.length;
		},

		hasFooterGap() {
			if (this.lastBlockIsGrayBlock) {
				return false;
			}

			return true;
		},
	},
};
</script>
