<template>
	<nav
		:is="tag"
		:id="computedId"
		class="c-card-list-section c-card-list-section--wide"
		:class="isGray && 'c-card-list-section--block-gray'"
	>
		<div
			class="flex justify-between"
			:class="{
				'mb-5xs/v': teaser,
				'mb-xs/v >=768:mb-sm/v': !teaser,
			}"
		>
			<!-- Heading -->
			<LongReadTarget
				v-if="title"
				class="relative"
				:container-id="computedId"
				:link-to-toc="{
					name: `Spring til &quot;${title}&quot; i indholdsfortegnelsen`,
					attrs: {
						'data-focus-ignore': true,
					},
				}"
				:title="title"
				:disabled="!showTitleInToc"
			>
				<BaseH2
					v-if="title"
					class="text-h3 font-light"
					v-html="title"
				/>
			</LongReadTarget>

			<!-- Link on desktop -->
			<BaseButton
				v-if="link && !showLinkAtBottom"
				:to="link.url"
				:target="link.target"
				class="
					<768:hidden
					ml-auto
					text-theme
					border border-theme
					bg-transparent
					hover:bg-theme-10
				"
				v-text="link.name"
			/>
		</div>

		<p
			v-if="teaser"
			class="
				>=1024:w-8/12col
				text-gray-darker text-body
				font-light
				mb-xs/v
				>=768:mb-sm/v
			"
			v-text="teaser"
		></p>

		<SwiperWrap
			v-if="items && items.length"
			ref="swiper"
			tag="div"
			:move-by="1"
			class="c-card-list-section__swiper"
			:items-wrapper-class-names="[
				'flex w-full pt-1 overflow-x-auto overflow-y-hidden gap-x-sm/h gap-y-sm/v',
				'>=768:grid >=768:grid-cols-2 >=1024:grid-cols-3 >=768:overflow-visible',
				'snap snap-x snap-mandatory',
			]"
		>
			<!-- Cards -->
			<template #items>
				<template v-for="item in items">
					<CardItemEmployee
						v-if="item.template === 'employee'"
						:key="item.id"
						:heading-level-offset="1"
						v-bind="{ ...item, noTeaser }"
					/>
					<CardItem
						v-else
						:key="item.id"
						:heading-level-offset="1"
						v-bind="{ ...item, noTeaser }"
					/>
				</template>

				<div
					key="showstopper"
					class="
						c-card-list-section__item-showstopper
						flex-shrink-0 flex-grow-0
						>=768:hidden
					"
					data-swiper-ignore
				></div>
			</template>

			<template #default="{ pagination }">
				<div class=">=768:hidden px-layout-margin">
					<div
						v-if="pagination.count > 1"
						class="flex items-center mt-2xs/v"
						aria-hidden="true"
					>
						<div
							role="progressbar"
							class="
								c-card-list-section__progress
								w-1/2
								h-2
								flex-shrink flex-grow
							"
							aria-label="viste placering i liste"
							:aria-valuenow="pagination.index + 1"
							:aria-valuetext="`${pagination.index + 1} af ${
								pagination.count
							}`"
							aria-valuemin="1"
							:aria-valuemax="pagination.count"
							:style="{
								'--progress': `${Math.round(
									((pagination.index + 1) /
										pagination.count) *
										100
								)}%`,
							}"
						>
							<div></div>
						</div>

						<span class="text-theme ml-2xs/h">
							{{ pagination.index + 1 }} / {{ pagination.count }}
						</span>
					</div>

					<!-- Link on mobile -->
					<BaseButton
						v-if="link && !showLinkAtBottom"
						:to="link.url"
						:target="link.target"
						class="
							mt-2xs/v
							text-theme
							border border-theme
							bg-transparent
							hover:bg-theme-10
						"
						v-text="link.name"
					/>
				</div>

				<!-- Link when shown at the bottom -->
				<div
					v-if="link && showLinkAtBottom"
					class="px-layout-margin flex justify-center mt-md/v"
				>
					<BaseButton
						:to="link.url"
						:target="link.target"
						class="
							text-theme
							border border-theme
							bg-transparent
							hover:bg-theme-10
						"
						v-text="link.name"
					/>
				</div>
			</template>
		</SwiperWrap>
	</nav>
</template>

<script>
import { LongReadTarget } from '~/components/shared/LongRead';
import SwiperWrap from '~/components/shared/SwiperWrap';
import CardItem from '~/components/main/CardItem';
import CardItemEmployee from '~/components/main/CardItemEmployee';

export default {
	name: 'CardListSection',

	components: {
		LongReadTarget,
		SwiperWrap,
		CardItem,
		CardItemEmployee,
	},

	props: {
		id: {
			type: String,
			default: null,
		},

		tag: {
			type: String,
			default: 'nav',
		},

		title: {
			type: String,
			default: null,
		},
		teaser: {
			type: String,
			default: null,
		},

		showTitleInToc: {
			type: Boolean,
			default: false,
		},

		link: {
			type: Object,
			default: null,
		},
		showLinkAtBottom: {
			type: Boolean,
			default: false,
		},

		items: {
			type: Array,
			default: () => [],
		},

		noTeaser: {
			type: Boolean,
			default: false,
		},

		isGray: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		computedId() {
			return this.id || `c-card-list-section-${this._uid}`;
		},
	},
};
</script>

<style lang="postcss">
.c-card-list-section {
	@apply isolate relative w-12/12col justify-self-end;
}
.c-card-list-section--block-gray {
	@apply pt-xl/v pb-2xl/v;

	&:before {
		@apply absolute w-visual-screen top-0 h-full bg-gray-light;
		left: calc((100% - var(--visual-viewport-width, 100vw)) / 2);
		z-index: -1;
		content: '';
	}
}

[class*='--block-gray']:not(.c-block-list-experts)
	+ .c-card-list-section[class*='--block-gray'] {
	padding-top: 0;
}

@screen <768 {
	.c-card-list-section__swiper {
		@apply w-visual-screen;
		margin-inline: calc((100% - var(--visual-viewport-width, 100vw)) / 2);

		& > .c-swiper-wrap__content {
			padding-left: var(
				--theme-layout-margin,
				var(--theme-layout-margin--sm)
			);

			& > * {
				@apply flex-shrink-0 flex-grow-0;
				width: calc(
					var(--theme-layout-column-of-4) * 3 +
						var(
							--theme-layout-gutter,
							var(--theme-layout-gutter--sm)
						) * 2 +
						var(
							--theme-horizontalSpacing-sm-h,
							var(--theme-horizontalSpacing-sm-h--sm)
						)
				);
			}
		}
	}

	.c-card-list-section__item-showstopper {
		width: calc(
			var(--theme-layout-margin, var(--theme-layout-margin--sm)) -
				var(
					--theme-horizontalSpacing-sm-h,
					var(--theme-horizontalSpacing-sm-h--sm)
				)
		) !important;
	}
}

.c-card-list-section__progress {
	@apply bg-gray-medium;

	& > div {
		@apply h-full bg-theme;
		width: var(--progress, 0%);
		transition: width 0.1s ease;
	}
}
</style>
