<template>
	<SkyAppear
		v-slot="{ intersectionJourney, intersectionRatio }"
		:options="{
			initState: 'outside',
			appearOnce: false,
			triggerEvents: ['resize', 'scroll'],
			thresholds: [0, 0.25, 0.5, 0.75, 1],
			delay: 'animationFrame',
		}"
	>
		<NuxtLinkExt
			:is="link ? 'NuxtLinkExt' : 'div'"
			:id="`block-${$vnode.key}`"
			class="
				c-block-list-experts
				c-block-list-experts--block-gray
				c-block-list-experts--wide
			"
			:class="{
				'c-block-list-experts--has-link': link,
			}"
			:to="link ? link.url : null"
			:target="link ? link.target : null"
		>
			<!-- Background graphic -->
			<TransitionExt name="fade-in-out" duration="400" appear>
				<figure
					v-if="computedItems"
					class="
						c-block-list-experts__background
						pointer-events-none
						absolute
						w-visual-screen
						h-full
						flex
						justify-center
						items-center
						overflow-hidden
					"
				>
					<div
						class="
							absolute
							w-full
							grid grid-cols-12
							auto-rows-fr
							gap-16
						"
						:style="{
							height: '300%',
							transform: `translateY(${
								Math.round(
									(100 / 3 -
										(100 / 3) * 2 * intersectionJourney) *
										100
								) / 100
							}%)`,
							opacity: `${
								Math.round(intersectionRatio * 100) / 100
							}`,
						}"
					>
						<!-- Entry section -->
						<div
							class="
								flex
								justify-center
								items-center
								col-start-9 col-span-2
								row-start-1 row-span-2
							"
						>
							<div
								class="relative w-auto h-full"
								:style="{
									aspectRatio: '174 / 202',
									maxHeight: '202px',
								}"
							>
								<div
									class="c-block-list-experts__decoration"
									:style="{
										left: '80%',
										top: '20%',
										width: '60%',
										height: '110%',
									}"
								></div>
								<div
									class="c-block-list-experts__image-wrapper"
								>
									<BaseImg
										v-if="computedItems[0]"
										:src="
											computedItems[0].cropUrl ||
											computedItems[0].url
										"
										:alt="computedItems[0].altText"
										:width="computedItems[0].width"
										:height="computedItems[0].height"
										sizes="<1441:174px"
										:ratio="174 / 202"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>
						<div
							class="
								flex
								justify-end
								items-center
								col-start-3 col-span-2
								row-start-2 row-span-1
							"
						>
							<div
								class="relative w-auto h-full"
								:style="{
									aspectRatio: '1',
									maxHeight: '134px',
								}"
							>
								<div
									class="
										c-block-list-experts__decoration
										rounded-full
									"
									:style="{
										left: '70%',
										top: '70%',
										width: '50%',
										height: '50%',
									}"
								></div>
								<div
									class="
										c-block-list-experts__image-wrapper
										c-block-list-experts__image-wrapper--round
									"
								>
									<BaseImg
										v-if="computedItems[1]"
										:src="
											computedItems[1].cropUrl ||
											computedItems[1].url
										"
										:alt="computedItems[1].altText"
										:width="computedItems[1].width"
										:height="computedItems[1].height"
										sizes="<1441:134px"
										:ratio="1"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>
						<div
							class="
								hidden
								>=1024:flex
								justify-end
								items-center
								col-start-1 col-span-2
								row-start-3 row-span-2
							"
						>
							<div
								class="relative w-auto h-full"
								:style="{
									aspectRatio: '1',
									maxHeight: '175px',
								}"
							>
								<div
									class="
										c-block-list-experts__decoration
										rounded-r-full
									"
									:style="{
										left: '90%',
										width: '50%',
									}"
								></div>
								<div
									class="
										c-block-list-experts__image-wrapper
										c-block-list-experts__image-wrapper--round
									"
								>
									<BaseImg
										v-if="computedItems[2]"
										:src="
											computedItems[2].cropUrl ||
											computedItems[2].url
										"
										:alt="computedItems[2].altText"
										:width="computedItems[2].width"
										:height="computedItems[2].height"
										sizes="<1441:175px"
										:ratio="1"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>
						<div
							class="
								flex
								justify-center
								items-center
								col-start-10 col-span-3
								row-start-5
								>=1024:row-start-4
								row-span-1
								>=1024:row-span-3
							"
						>
							<div class="c-block-list-experts__image-wrapper">
								<BaseImg
									v-if="computedItems[3]"
									:src="
										computedItems[3].cropUrl ||
										computedItems[3].url
									"
									:alt="computedItems[3].altText"
									:width="computedItems[3].width"
									:height="computedItems[3].height"
									sizes="<1441:375px"
									:ratio="375 / 273"
									load-color="#ffffff"
									class="w-auto h-full object-cover"
									style="max-height: 273px"
									:img-attrs="{
										fetchpriority: 'low',
										class: 'object-cover',
									}"
								/>
							</div>
						</div>
						<div
							class="
								flex
								justify-start
								items-center
								col-start-6 col-span-1
								row-start-6 row-span-1
							"
						>
							<div
								class="relative w-auto"
								:style="{
									aspectRatio: '153/182',
									height: '150%',
									maxHeight: '182px',
								}"
							>
								<div
									class="c-block-list-experts__decoration"
									:style="{
										right: '20%',
										bottom: '50%',
										width: '200%',
									}"
								></div>
								<div
									class="c-block-list-experts__image-wrapper"
								>
									<BaseImg
										v-if="computedItems[4]"
										:src="
											computedItems[4].cropUrl ||
											computedItems[4].url
										"
										:alt="computedItems[4].altText"
										:width="computedItems[4].width"
										:height="computedItems[4].height"
										sizes="<1441:153px"
										:ratio="153 / 182"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>

						<!-- Main section -->
						<div
							class="
								flex
								justify-center
								items-center
								col-start-3 col-span-2
								row-start-7 row-span-2
							"
						>
							<div
								class="relative w-auto h-full"
								:style="{
									aspectRatio: '174 / 202',
									maxHeight: '202px',
								}"
							>
								<div
									class="c-block-list-experts__decoration"
									:style="{
										left: '80%',
										top: '20%',
										width: '60%',
										height: '110%',
										transform: `translateY(${
											Math.round(
												intersectionJourney * 100 * 1000
											) /
												1000 -
											50
										}%)`,
									}"
								></div>
								<div
									class="c-block-list-experts__image-wrapper"
								>
									<BaseImg
										v-if="computedItems[5]"
										:src="
											computedItems[5].cropUrl ||
											computedItems[5].url
										"
										:alt="computedItems[5].altText"
										:width="computedItems[5].width"
										:height="computedItems[5].height"
										sizes="<1441:174px"
										:ratio="174 / 202"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>
						<div
							class="
								flex
								justify-start
								items-center
								col-start-9 col-span-2
								row-start-8 row-span-1
							"
						>
							<div
								class="relative w-auto h-full"
								:style="{
									aspectRatio: '1',
									maxHeight: '134px',
								}"
							>
								<div
									class="
										c-block-list-experts__decoration
										rounded-full
									"
									:style="{
										left: '70%',
										top: '70%',
										width: '50%',
										height: '50%',
										transform: `translateY(${
											Math.round(
												intersectionJourney * 100 * 1000
											) /
												1000 -
											50
										}%)`,
									}"
								></div>
								<div
									class="
										c-block-list-experts__image-wrapper
										c-block-list-experts__image-wrapper--round
									"
								>
									<BaseImg
										v-if="computedItems[6]"
										:src="
											computedItems[6].cropUrl ||
											computedItems[6].url
										"
										:alt="computedItems[6].altText"
										:width="computedItems[6].width"
										:height="computedItems[6].height"
										sizes="<1441:134px"
										:ratio="1"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>
						<div
							class="
								hidden
								>=1024:flex
								justify-start
								items-center
								col-start-11 col-span-2
								row-start-9 row-span-2
							"
						>
							<div
								class="relative w-auto h-full"
								:style="{
									aspectRatio: '1',
									maxHeight: '175px',
								}"
							>
								<div
									class="
										c-block-list-experts__decoration
										rounded-r-full
									"
									:style="{
										left: '90%',
										width: '50%',
										transform: `translateY(${
											Math.round(
												intersectionJourney * 100 * 1000
											) /
												1000 -
											50
										}%)`,
									}"
								></div>
								<div
									class="
										c-block-list-experts__image-wrapper
										c-block-list-experts__image-wrapper--round
									"
								>
									<BaseImg
										v-if="computedItems[7]"
										:src="
											computedItems[7].cropUrl ||
											computedItems[7].url
										"
										:alt="computedItems[7].altText"
										:width="computedItems[7].width"
										:height="computedItems[7].height"
										sizes="<1441:175px"
										:ratio="1"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>
						<div
							class="
								flex
								justify-center
								items-center
								col-start-1 col-span-3
								row-start-11
								>=1024:row-start-10
								row-span-1
								>=1024:row-span-3
							"
						>
							<div
								class="relative w-auto h-full"
								:style="{
									aspectRatio: '375/273',
									maxHeight: '273px',
								}"
							>
								<div
									class="c-block-list-experts__image-wrapper"
								>
									<BaseImg
										v-if="computedItems[8]"
										:src="
											computedItems[8].cropUrl ||
											computedItems[8].url
										"
										:alt="computedItems[8].altText"
										:width="computedItems[8].width"
										:height="computedItems[8].height"
										sizes="<1441:375px"
										:ratio="375 / 273"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>
						<div
							class="
								flex
								justify-end
								items-center
								col-start-7 col-span-1
								row-start-12 row-span-1
							"
						>
							<div
								class="relative w-auto"
								:style="{
									aspectRatio: '153/182',
									height: '150%',
									maxHeight: '182px',
								}"
							>
								<div
									class="c-block-list-experts__decoration"
									:style="{
										right: '20%',
										bottom: '50%',
										width: '200%',
										transform: `translateY(${
											Math.round(
												intersectionJourney * 100 * 1000
											) /
												1000 -
											50
										}%)`,
									}"
								></div>
								<div
									class="c-block-list-experts__image-wrapper"
								>
									<BaseImg
										v-if="computedItems[9]"
										:src="
											computedItems[9].cropUrl ||
											computedItems[9].url
										"
										:alt="computedItems[9].altText"
										:width="computedItems[9].width"
										:height="computedItems[9].height"
										sizes="<1441:153px"
										:ratio="153 / 182"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>

						<!-- Exit section -->
						<div
							class="
								flex
								justify-center
								items-center
								col-start-9 col-span-2
								row-start-13 row-span-2
							"
						>
							<div
								class="relative w-auto h-full"
								:style="{
									aspectRatio: '174 / 202',
									maxHeight: '202px',
								}"
							>
								<div
									class="c-block-list-experts__decoration"
									:style="{
										left: '80%',
										top: '20%',
										width: '60%',
										height: '110%',
									}"
								></div>
								<div
									class="c-block-list-experts__image-wrapper"
								>
									<BaseImg
										v-if="computedItems[10]"
										:src="
											computedItems[10].cropUrl ||
											computedItems[10].url
										"
										:alt="computedItems[10].altText"
										:width="computedItems[10].width"
										:height="computedItems[10].height"
										sizes="<1441:174px"
										:ratio="174 / 202"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>
						<div
							class="
								flex
								justify-end
								items-center
								col-start-3 col-span-2
								row-start-14 row-span-1
							"
						>
							<div
								class="relative w-auto h-full"
								:style="{
									aspectRatio: '1',
									maxHeight: '134px',
								}"
							>
								<div
									class="
										c-block-list-experts__decoration
										rounded-full
									"
									:style="{
										left: '70%',
										top: '70%',
										width: '50%',
										height: '50%',
									}"
								></div>
								<div
									class="
										c-block-list-experts__image-wrapper
										c-block-list-experts__image-wrapper--round
									"
								>
									<BaseImg
										v-if="computedItems[1]"
										:src="
											computedItems[1].cropUrl ||
											computedItems[1].url
										"
										:alt="computedItems[1].altText"
										:width="computedItems[1].width"
										:height="computedItems[1].height"
										sizes="<1441:134px"
										:ratio="1"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>
						<div
							class="
								hidden
								>=1024:flex
								justify-end
								items-center
								col-start-1 col-span-2
								row-start-15 row-span-2
							"
						>
							<div
								class="relative w-auto h-full"
								:style="{
									aspectRatio: '1',
									maxHeight: '175px',
								}"
							>
								<div
									class="
										c-block-list-experts__decoration
										rounded-r-full
									"
									:style="{
										left: '90%',
										width: '50%',
									}"
								></div>
								<div
									class="
										c-block-list-experts__image-wrapper
										c-block-list-experts__image-wrapper--round
									"
								>
									<BaseImg
										v-if="computedItems[12]"
										:src="
											computedItems[12].cropUrl ||
											computedItems[12].url
										"
										:alt="computedItems[12].altText"
										:width="computedItems[12].width"
										:height="computedItems[12].height"
										sizes="<1441:175px"
										:ratio="1"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>
						<div
							class="
								flex
								justify-center
								items-center
								col-start-10 col-span-3
								row-start-17
								>=1024:row-start-16
								row-span-1
								>=1024:row-span-3
							"
						>
							<div class="c-block-list-experts__image-wrapper">
								<BaseImg
									v-if="computedItems[13]"
									:src="
										computedItems[13].cropUrl ||
										computedItems[13].url
									"
									:alt="computedItems[13].altText"
									:width="computedItems[13].width"
									:height="computedItems[13].height"
									sizes="<1441:375px"
									:ratio="375 / 273"
									load-color="#ffffff"
									class="w-auto h-full object-cover"
									style="max-height: 273px"
									:img-attrs="{
										fetchpriority: 'low',
										class: 'object-cover',
									}"
								/>
							</div>
						</div>
						<div
							class="
								flex
								justify-start
								items-center
								col-start-6 col-span-1
								row-start-18 row-span-1
							"
						>
							<div
								class="relative w-auto"
								:style="{
									aspectRatio: '153/182',
									height: '150%',
									maxHeight: '182px',
								}"
							>
								<div
									class="c-block-list-experts__decoration"
									:style="{
										right: '20%',
										bottom: '50%',
										width: '200%',
									}"
								></div>
								<div
									class="c-block-list-experts__image-wrapper"
								>
									<BaseImg
										v-if="computedItems[14]"
										:src="
											computedItems[14].cropUrl ||
											computedItems[14].url
										"
										:alt="computedItems[14].altText"
										:width="computedItems[14].width"
										:height="computedItems[14].height"
										sizes="<1441:153px"
										:ratio="153 / 182"
										load-color="#ffffff"
										class="w-full h-full"
										:img-attrs="{
											fetchpriority: 'low',
											class: 'object-cover',
										}"
										quality="70"
									/>
								</div>
							</div>
						</div>
					</div>
				</figure>
			</TransitionExt>

			<!-- Text content and space making -->
			<div
				class="
					c-block-list-experts__content
					relative
					w-visual-screen
					h-full
					flex-shrink-0 flex
					justify-center
					items-center
					text-center
				"
			>
				<LongReadTarget
					v-if="title"
					class="relative"
					:container-id="`block-${$vnode.key}`"
					:link-to-toc="{
						name: `Spring til &quot;${title}&quot; i indholdsfortegnelsen`,
						attrs: {
							'data-focus-ignore': true,
						},
					}"
					:title="title"
					:disabled="!showTitleInToc"
				>
					<BaseH2
						class="
							text-cta-title
							font-extralight
							max-w-4/4col
							>=768:max-w-8/12col
							>=1024:max-w-6/12col
						"
						v-html="title"
					/>
				</LongReadTarget>
			</div>

			<!-- Indicator -->
			<SvgIconGt class="c-block-list-experts__link-indicator" />
		</NuxtLinkExt>
	</SkyAppear>
</template>

<script>
import { SkyAppear } from 'sky-appear';

import { LongReadTarget } from '~/components/shared/LongRead';
import SvgIconGt from '~/assets/svgs/icon-gt.svg?inline';

export default {
	name: 'BlockListExperts',
	components: {
		SkyAppear,
		LongReadTarget,
		SvgIconGt,
	},
	inheritAttrs: false,

	props: {
		link: {
			type: Object,
			default: null,
		},

		showTitleInToc: Boolean,
		title: {
			type: String,
			default: '',
		},

		items: {
			type: Array,
			default: () => [],
		},
	},

	computed: {
		computedItems() {
			const imageItems = this.items
				.map((item) => {
					return item?.image;
				})
				.filter(Boolean);

			if (imageItems.length) {
				let returnValue = [...imageItems];
				while (returnValue.length < 15) {
					returnValue = [...returnValue, ...imageItems];
				}
				return returnValue.slice(0, 15);
			}

			return null;
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-experts {
	@apply isolate relative w-12/12col justify-self-end flex justify-center items-center;
	/* pointer-events: none; */

	&:before {
		@apply absolute w-visual-screen top-0 h-full bg-gray-light;
		left: calc((100% - var(--visual-viewport-width, 100vw)) / 2);
		z-index: -1;
		content: '';
	}

	&.c-block-list-experts--has-link:hover,
	&.c-block-list-experts--has-link:focus-visible {
		& .c-block-list-experts__image-wrapper {
			& picture {
				scale: 1.05;
			}
		}
		& .c-block-list-experts__image-wrapper--round {
			& picture {
				clip-path: circle(47.5% at 50% 50%);
			}
		}
		& .c-block-list-experts__decoration {
			translate: 8px 0;
		}
	}
}
.c-block-list-experts__background {
	transform: translateZ(0);
}
.c-block-list-experts__image-wrapper {
	@apply relative w-auto h-auto max-w-full max-h-full overflow-hidden bg-white;

	& picture {
		transition: scale 0.2s ease-in-out;
	}
}
.c-block-list-experts__image-wrapper--round {
	@apply rounded-full overflow-hidden;
	clip-path: circle(50% at 50% 50%);

	& picture {
		transition: scale 0.2s ease-in-out, clip-path 0.2s ease-in-out;
		clip-path: circle(50% at 50% 50%);
	}
}
.c-block-list-experts__decoration {
	@apply absolute left-auto top-auto w-full h-full bg-gray;
	transition: translate 0.2s ease-in-out;
}
.c-block-list-experts__content {
	@apply text-gray-darker;
	padding-top: 230px;
	padding-bottom: 280px;
}

[class*='--block-gray'] + .c-block-list-experts {
	&:before {
		@apply bg-gray;
	}

	& .c-block-list-experts__decoration {
		@apply bg-gray-medium;
	}
}

.c-block-list-experts__link-indicator {
	@apply absolute right-0 bottom-lg/v;
	width: 38px;
	height: 38px;
	margin-bottom: -2px;

	color: #565656;

	transition: opacity 0.2s, translate 0.2s ease-in-out;
	translate: -16px 0;
	opacity: 0;
}

.c-block-list-experts--has-link:hover .c-block-list-experts__link-indicator,
.c-block-list-experts--has-link:focus-within
	.c-block-list-experts__link-indicator {
	translate: 0;
	opacity: 1;
}

@screen <768 {
	.c-block-list-experts__link-indicator {
		width: 28px;
		height: 28px;
		translate: 0;
		opacity: 1;
	}
}
</style>
