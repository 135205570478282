import { render, staticRenderFns } from "./FrontpageFocus.vue?vue&type=template&id=dec5b95a&"
import script from "./FrontpageFocus.vue?vue&type=script&lang=js&"
export * from "./FrontpageFocus.vue?vue&type=script&lang=js&"
import style0 from "./FrontpageFocus.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports