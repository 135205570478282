var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"nav",staticClass:"c-card-list-section c-card-list-section--wide",class:_vm.isGray && 'c-card-list-section--block-gray',attrs:{"id":_vm.computedId}},[_c('div',{staticClass:"flex justify-between",class:{
			'mb-5xs/v': _vm.teaser,
			'mb-xs/v >=768:mb-sm/v': !_vm.teaser,
		}},[(_vm.title)?_c('LongReadTarget',{staticClass:"relative",attrs:{"container-id":_vm.computedId,"link-to-toc":{
				name: ("Spring til \"" + _vm.title + "\" i indholdsfortegnelsen"),
				attrs: {
					'data-focus-ignore': true,
				},
			},"title":_vm.title,"disabled":!_vm.showTitleInToc}},[(_vm.title)?_c('BaseH2',{staticClass:"text-h3 font-light",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.link && !_vm.showLinkAtBottom)?_c('BaseButton',{staticClass:"\n\t\t\t\t<768:hidden\n\t\t\t\tml-auto\n\t\t\t\ttext-theme\n\t\t\t\tborder border-theme\n\t\t\t\tbg-transparent\n\t\t\t\thover:bg-theme-10\n\t\t\t",attrs:{"to":_vm.link.url,"target":_vm.link.target},domProps:{"textContent":_vm._s(_vm.link.name)}}):_vm._e()],1),_vm._v(" "),(_vm.teaser)?_c('p',{staticClass:"\n\t\t\t>=1024:w-8/12col\n\t\t\ttext-gray-darker text-body\n\t\t\tfont-light\n\t\t\tmb-xs/v\n\t\t\t>=768:mb-sm/v\n\t\t",domProps:{"textContent":_vm._s(_vm.teaser)}}):_vm._e(),_vm._v(" "),(_vm.items && _vm.items.length)?_c('SwiperWrap',{ref:"swiper",staticClass:"c-card-list-section__swiper",attrs:{"tag":"div","move-by":1,"items-wrapper-class-names":[
			'flex w-full pt-1 overflow-x-auto overflow-y-hidden gap-x-sm/h gap-y-sm/v',
			'>=768:grid >=768:grid-cols-2 >=1024:grid-cols-3 >=768:overflow-visible',
			'snap snap-x snap-mandatory' ]},scopedSlots:_vm._u([{key:"items",fn:function(){return [_vm._l((_vm.items),function(item){return [(item.template === 'employee')?_c('CardItemEmployee',_vm._b({key:item.id,attrs:{"heading-level-offset":1}},'CardItemEmployee',Object.assign({}, item, {noTeaser: _vm.noTeaser}),false)):_c('CardItem',_vm._b({key:item.id,attrs:{"heading-level-offset":1}},'CardItem',Object.assign({}, item, {noTeaser: _vm.noTeaser}),false))]}),_vm._v(" "),_c('div',{key:"showstopper",staticClass:"\n\t\t\t\t\tc-card-list-section__item-showstopper\n\t\t\t\t\tflex-shrink-0 flex-grow-0\n\t\t\t\t\t>=768:hidden\n\t\t\t\t",attrs:{"data-swiper-ignore":""}})]},proxy:true},{key:"default",fn:function(ref){
		var pagination = ref.pagination;
return [_c('div',{staticClass:">=768:hidden px-layout-margin"},[(pagination.count > 1)?_c('div',{staticClass:"flex items-center mt-2xs/v",attrs:{"aria-hidden":"true"}},[_c('div',{staticClass:"\n\t\t\t\t\t\t\tc-card-list-section__progress\n\t\t\t\t\t\t\tw-1/2\n\t\t\t\t\t\t\th-2\n\t\t\t\t\t\t\tflex-shrink flex-grow\n\t\t\t\t\t\t",style:({
							'--progress': ((Math.round(
								((pagination.index + 1) /
									pagination.count) *
									100
							)) + "%"),
						}),attrs:{"role":"progressbar","aria-label":"viste placering i liste","aria-valuenow":pagination.index + 1,"aria-valuetext":((pagination.index + 1) + " af " + (pagination.count)),"aria-valuemin":"1","aria-valuemax":pagination.count}},[_c('div')]),_vm._v(" "),_c('span',{staticClass:"text-theme ml-2xs/h"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(pagination.index + 1)+" / "+_vm._s(pagination.count)+"\n\t\t\t\t\t")])]):_vm._e(),_vm._v(" "),(_vm.link && !_vm.showLinkAtBottom)?_c('BaseButton',{staticClass:"\n\t\t\t\t\t\tmt-2xs/v\n\t\t\t\t\t\ttext-theme\n\t\t\t\t\t\tborder border-theme\n\t\t\t\t\t\tbg-transparent\n\t\t\t\t\t\thover:bg-theme-10\n\t\t\t\t\t",attrs:{"to":_vm.link.url,"target":_vm.link.target},domProps:{"textContent":_vm._s(_vm.link.name)}}):_vm._e()],1),_vm._v(" "),(_vm.link && _vm.showLinkAtBottom)?_c('div',{staticClass:"px-layout-margin flex justify-center mt-md/v"},[_c('BaseButton',{staticClass:"\n\t\t\t\t\t\ttext-theme\n\t\t\t\t\t\tborder border-theme\n\t\t\t\t\t\tbg-transparent\n\t\t\t\t\t\thover:bg-theme-10\n\t\t\t\t\t",attrs:{"to":_vm.link.url,"target":_vm.link.target},domProps:{"textContent":_vm._s(_vm.link.name)}})],1):_vm._e()]}}],null,false,3202678862)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }