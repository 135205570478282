<template>
	<!-- Outer layer is for being able to overwrite the card color -->
	<ScopedColorTheme
		:is="theme ? 'ScopedColorTheme' : 'div'"
		class="c-card-item"
		:class="{
			'c-card-item--has-link': url,
		}"
		:theme="theme"
	>
		<!-- The actual card starts from here -->
		<Component
			:is="url ? 'LinkTile' : tag"
			:id="computedId"
			:tag="tag"
			:aria-labelledby="ariaLabelledBy"
			:to="url"
		>
			<slot name="image" v-bind="slotProps">
				<figure
					v-if="iconUrl && (!template || template === 'area')"
					class="
						c-card-item__image c-card-item__image--icon
						px-xs/h
						flex
						items-center
					"
				>
					<AreaIcon class="w-auto h-1/2" :src="iconUrl" />
				</figure>
				<figure
					v-else-if="(image || usePlaceholder) && template !== 'area'"
					class="c-card-item__image"
				>
					<BaseImg
						v-if="image"
						class="flex-shrink-0"
						:src="image.cropUrl || image.url"
						:alt="image.altText"
						:sizes="
							nuxtImgSizes('<1200:100vw <1400:600px <1441:800px')
						"
						:width="image.width"
						:height="image.height"
						:ratio="413 / 197"
						:load-color="imageLoadColor"
					/>
					<BasePlaceholder
						v-else
						class="h-full"
						:index="placeholderIndex"
					/>
				</figure>
			</slot>

			<div class="flex flex-col flex-grow px-xs/h py-xs/v">
				<slot name="header" v-bind="slotProps">
					<small v-if="label || computedDate">
						<span
							v-if="label"
							:id="labelId"
							class="
								inline-block
								font-medium
								uppercase
								text-label-sm text-theme
							"
							v-text="label"
						></span>
						<br v-if="label && computedDate" />
						<span
							v-if="computedDate"
							class="
								c-card-item__date-stamp
								inline-block
								font-medium
								uppercase
								text-label-sm text-gray-darker
							"
							v-text="computedDate"
						></span>
					</small>
				</slot>

				<slot name="content" v-bind="slotProps">
					<div
						v-if="
							computedName ||
							(computedTeaser && template !== 'employee')
						"
						class="flex flex-col mt-4xs/v mb-4xs/v gap-y-2xs/v"
					>
						<BaseH4
							v-if="computedName"
							:id="nameId"
							:level-offset="headingLevelOffset"
							class="text-card-title-sm-2"
							v-html="computedName"
						/>
						<p
							v-if="computedApaDetails"
							class="
								font-light
								text-body-xs
								whitespace-pre-wrap
								pr-xs/h
							"
							v-html="computedApaDetails"
						></p>
						<p
							v-else-if="
								computedTeaser && template !== 'employee'
							"
							class="
								font-light
								text-body-xs
								whitespace-pre-wrap
								pr-xs/h
							"
							v-html="computedTeaser"
						></p>
						<slot
							v-if="$scopedSlots.content"
							v-bind="slotProps"
						></slot>
					</div>
				</slot>
				<slot v-if="!$scopedSlots.content" v-bind="slotProps"></slot>

				<slot name="footer" v-bind="slotProps">
					<div class="mt-auto">
						<AreaReferences
							v-if="areas"
							class="c-card-item__areas mt-xs/v"
							:items="areas"
						/>
					</div>
				</slot>
				<slot name="indicator" v-bind="slotProps">
					<SvgIconGt class="c-card-item__link-indicator" />
				</slot>
			</div>
		</Component>
	</ScopedColorTheme>
</template>

<script>
import ScopedColorTheme from '~/components/main/ScopedColorTheme';
import LinkTile from '~/components/shared/LinkTile';
import AreaIcon from '~/components/main/AreaIcon';
import AreaReferences from '~/components/main/AreaReferences';
import SvgIconGt from '~/assets/svgs/icon-gt.svg?inline';
import { nuxtImgSizes } from '~/assets/js/utilities/helpers';

export default {
	name: 'CardItem',

	components: {
		ScopedColorTheme,
		LinkTile,
		AreaIcon,
		AreaReferences,
		SvgIconGt,
	},

	inject: {
		injectedTheme: {
			from: 'theme',
			default: () => ({ color: 'red' }),
		},
	},

	props: {
		tag: {
			type: String,
			default: 'article',
		},

		theme: {
			type: String,
			default: null,
		},

		id: {
			type: [Number, String],
			default: '',
		},

		template: {
			type: String,
			default: '',
		},

		headingLevelOffset: {
			type: [Number, String],
			default: 2,
		},

		label: {
			type: String,
			default: '',
		},

		date: {
			type: String,
			default: '',
		},

		name: {
			type: String,
			default: '',
		},

		title: {
			type: String,
			default: '',
		},

		teaser: {
			type: String,
			default: '',
		},

		url: {
			type: String,
			default: '',
		},

		image: {
			type: Object,
			default: null,
		},

		iconUrl: {
			type: String,
			default: '',
		},

		usePlaceholder: {
			type: [Boolean, Number],
			default: true,
		},

		placeholderIndex: {
			type: [Number, String],
			default: -1,
		},

		details: {
			type: Object,
			default: () => ({}),
		},

		noTeaser: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const lang =
			{
				11050: 'en',
			}[this.$store.state?.site?.cultureId] || 'da';
		return {
			lang,
		};
	},

	computed: {
		computedId() {
			if (typeof this.id === 'number') {
				return `c-card-item-${this._uid}-${this.id}`;
			}
			return this.id || `c-card-item-${this._uid}`;
		},

		computedTheme() {
			if (this.theme && this.theme === this.injectedTheme.color) {
				return '';
			}
			return this.theme || this.injectedTheme.color || 'red';
		},
		computedName() {
			return this.title || this.name;
		},
		computedApaDetails() {
			if (this.details.apa && this.lang === 'en') {
				return this.details.apa;
			}
			return '';
		},
		computedTeaser() {
			if (
				this.noTeaser ||
				['newsPage', 'eventPage', 'publication', 'project'].includes(
					this.template
				)
			) {
				return '';
			}
			return this.teaser;
		},

		computedDate() {
			if (
				!this.template ||
				['newsPage', 'eventPage', 'publication', 'project'].includes(
					this.template
				)
			) {
				return this.date;
			}
			return '';
		},

		labelId() {
			return this.label ? `${this.computedId}__label` : null;
		},

		nameId() {
			return this.computedName ? `${this.computedId}__title` : null;
		},

		ariaLabelledBy() {
			return (
				[this.labelId, this.nameId, this.computedId]
					.filter(Boolean)
					.join(' ') || null
			);
		},

		areas() {
			if (
				!this.template ||
				[
					'newsPage',
					'eventPage',
					'publication',
					'project',
					'employee',
				].includes(this.template)
			) {
				return this.details?.areas?.length && this.details?.areas;
			}
			return null;
		},

		imageLoadColor() {
			if ((this.computedTheme || this.theme) === 'red') {
				return '#F9E9EA';
			}
			return '#DCE4EF';
		},

		slotProps() {
			return {
				id: this.computedId,
				name: this.computedName,
				nameId: this.nameId,
				teaser: this.computedTeaser,
				date: this.computedDate,
				areas: this.areas,
				imageLoadColor: this.imageLoadColor,
			};
		},
	},

	methods: {
		nuxtImgSizes,
	},
};
</script>

<style lang="postcss">
.c-card-item > * {
	@apply w-full h-full relative isolate flex flex-col items-stretch bg-white text-text;

	&:before,
	&:after {
		@apply absolute left-0 top-0 w-full h-full;
		@apply transition-opacity duration-200 opacity-0 pointer-events-none;
		content: '';
	}
	&:before {
		@apply bg-theme-40 bg-opacity-20;
		z-index: -1;
	}
	&:after {
		@apply border border-solid border-theme-40;
		top: -1px;
		left: -1px;
		width: calc(100% + 1.01px);
		height: calc(100% + 1.01px);
	}
}
.c-card-item--has-link > * {
	&:hover:before,
	&:focus-within:before,
	&:hover:after,
	&:focus-within:after {
		@apply opacity-100;
	}
}

.c-card-item__image {
	overflow: hidden;
	aspect-ratio: 405 / 197;

	& > .c-nuxt-picture-ext,
	& > .c-base-placeholder {
		transition: translate 0.2s ease-in-out;
		translate: -8px 0;
		width: calc(100% + 9px) !important;
	}
}
.c-card-item__image--icon > * {
	transition: translate 0.2s ease-in-out;
}

.c-card-item:hover .c-card-item__image > .c-nuxt-picture-ext,
.c-card-item:focus-within .c-card-item__image > .c-nuxt-picture-ext,
.c-card-item:hover .c-card-item__image > .c-base-placeholder,
.c-card-item:focus-within .c-card-item__image > .c-base-placeholder {
	translate: 0;
}
.c-card-item--has-link:hover .c-card-item__image--icon > *,
.c-card-item--has-link:focus-within .c-card-item__image--icon > * {
	translate: 8px 0;
}

.c-card-item__link-indicator {
	@apply absolute right-sm/h bottom-xs/v >=1200:bottom-sm/v text-theme;
	width: 21px;
	height: 21px;
	margin-bottom: -2px;

	transition: opacity 0.2s, translate 0.2s ease-in-out;
	translate: -16px 0;
	opacity: 0;
}

.c-card-item--has-link:hover .c-card-item__link-indicator,
.c-card-item--has-link:focus-within .c-card-item__link-indicator {
	translate: 0;
	opacity: 1;
}

.c-card-item__areas {
	margin-inline: -4px;
	width: calc(100% + 8px);
}
.c-card-item__areas > :last-child {
	margin-right: 42px;
}
</style>
