var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-frontpage-focus"},[(_vm.title)?_c('BaseH2',{staticClass:"text-h3 text-gray-darker mb-sm/v",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),(_vm.items && _vm.items.length)?_c('div',{staticClass:"\n\t\t\tgrid grid-cols-1\n\t\t\t>=768:grid-cols-2\n\t\t\tgap-x-1/12col gap-y-xl/v\n\t\t\tw-full\n\t\t",class:{
			'>=768:border-b >=768:border-gray-medium': !_vm.noBottomLine,
		}},_vm._l((_vm.items),function(item,index){return _c('ScopedColorTheme',{key:item.id,staticClass:"w-full pb-sm/v >=768:pb-lg/v border-gray-medium",class:{
				'border-b': index < _vm.items.length - 1 || !_vm.noBottomLine,
				'>=768:border-b-0': index > _vm.items.length - 3,
				'>=768:row-span-2': index >= 1 && index <= 2,
			},attrs:{"theme":item.colorScheme || 'blue'}},[_c('LinkTile',{staticClass:"group w-full",class:{
					'>=768:flex >=768:gap-x-sm/h':
						index === 0 || index === 3,
					'>=768:h-full':
						(index === 0 || index === 3) && item.image,
				},attrs:{"to":item.url,"aria-labelledby":("c-frontpage-focus__item-name-" + (item.id))}},[(item.image)?_c('BaseImg',{staticClass:"\n\t\t\t\t\t\tc-frontpage-focus__image\n\t\t\t\t\t\tw-full\n\t\t\t\t\t\tflex\n\t\t\t\t\t\tjustify-end\n\t\t\t\t\t\toverflow-hidden\n\t\t\t\t\t\tmb-xs/v\n\t\t\t\t\t",class:{
						'>=768:w-2/12col >=768:h-full >=768:mb-0':
							index === 0 || index === 3,
					},attrs:{"src":item.image.cropUrl || item.image.url,"alt":item.image.altText,"sizes":_vm.nuxtImgSizes('<768:92vw <1400:43vw <1441:580px'),"img-attrs":{
						class: 'object-cover transform group-data-hover:translate-x-8 group-focus-within:translate-x-8 transition-transform duration-500',
					},"width":item.image.width,"height":item.image.height,"ratio":351 / 193}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full h-full flex-1",class:{
						'>=768:w-1/2': index === 0 || index === 3,
					}},[(item.label)?_c('small',{staticClass:"\n\t\t\t\t\t\t\tfont-medium\n\t\t\t\t\t\t\tuppercase\n\t\t\t\t\t\t\ttext-label-sm text-theme\n\t\t\t\t\t\t\tmb-8\n\t\t\t\t\t\t",domProps:{"textContent":_vm._s(item.label)}}):_vm._e(),_vm._v(" "),(item.title || item.name)?_c('BaseH3',{staticClass:"\n\t\t\t\t\t\t\ttext-sub-teaser\n\t\t\t\t\t\t\t>=768:text-card-title >=768:font-semibold\n\t\t\t\t\t\t",attrs:{"id":("c-frontpage-focus__item-name-" + (item.id)),"level-offset":_vm.title ? 2 : 1},domProps:{"innerHTML":_vm._s(item.title || item.name)}}):_vm._e(),_vm._v(" "),(item.teaser)?_c('p',{staticClass:"\n\t\t\t\t\t\t\thidden\n\t\t\t\t\t\t\t>=768:block\n\t\t\t\t\t\t\tmt-16\n\t\t\t\t\t\t\tfont-light\n\t\t\t\t\t\t\ttext-body\n\t\t\t\t\t\t",domProps:{"innerHTML":_vm._s(item.teaser)}}):_vm._e()],1)],1)],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }